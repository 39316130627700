.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .droppable-element {
    width: 200px;
    text-align: center;
    background: transparent;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
  }
  