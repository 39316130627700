nav {
  background-color: black;
  padding-left: 30px;
  padding-right: 10px;
  height: 60px;
}

.navbar {
  display: flex;
  align-items: center;
}

.logo {
  display: inline-block;
  color: white;
  margin: 0;
  white-space: nowrap;
}

.nav-links {
  list-style: none;
  font-size: 20px;
  display: flex;
  margin: 5px 0;
}

.nav-item {
  display: inline-flex;
}

.nav-item a {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: white;
  white-space: nowrap;
}

.nav-item:hover {
  background-color: white;
}

.nav-item:hover a {
  color: darkblue;
}

.flex-1 {
  flex: 1;
}
.navbar .avatar {
  width: 30px;
  height: 30px;
  align-self: center;
  margin-right: 20px;
  object-fit: cover;
}
