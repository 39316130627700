.home-page .reseed-btn {
  width: auto;
}

.home-page .name {
  font-size: 20px;
  font-weight: bold;
}
.carousel-container {
  overflow: hidden;
  position: relative;
  height: 500px;
  border-color: #4b5563;
  width: 100%;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1800px;
  height: 95%;
  &:hover {
    transform: scale(0.9);
    box-shadow: 5px 5px 8px rgba(222, 222, 222, 0.362),
      10px 10px 8px rgba(152, 133, 133, 0.392),
      15px 15px 8px rgba(139, 154, 139, 0.303);
  }
}

.carousel-card > img {
  width: 800px;
  height: 95%;
  border-radius: 3%;
}
@keyframes slide {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.carousel-track {
  background-color: rgba(0,0,0,0);
  display: flex;
  position: absolute;
  left: 0;
  justify-content: center;
  align-items: center;
  height: 80px;
  gap: 16px;
  width: 200%;
  height: 100%;
  animation: slide 30s linear infinite;
  transition-duration: 10s;
}

