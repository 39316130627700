.login {
  display: flex;
  justify-content: center;
}

.login .container {
  max-width: 400px;
  flex: 1;
}

.login .fb {
  background-color: #3b5998;
  color: white;
  display: block;
  text-align: center;
  border-radius:7px;
}

.login .google {
  background-color: #dd4b39;
  color: white;
  display: block;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius:7px;
}

.login .login-text {
  display: inline-block;
  min-width: 250px;
}

.login .logins {
  margin: 5px 0;
}
