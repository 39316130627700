html,
body,
#root {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  
}

a {
  text-decoration: none;
}



.controls {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
}

.controls-right {
  margin-left: auto;
}

.state {
  margin: 10px 0;
  font-family: monospace;
}

.state-title {
  color: #999;
  text-transform: uppercase;
}

.submit {
  background-color: gray;
  color: white;
}

.bold {
  font-weight: bold;
}

.text-light {
  color: gray;
}

.error {
  color: red;
  margin: 0;
}

.error-center {
  color: red;
  margin: 0;
  text-align: center;
}

button:disabled,
button[disabled] {
  border: 10px solid #999999;
  background-color: #f50e0e;
  color: #666666;
  /* cursor: not-allowed; */
}

.active {
  background-color: red;
  /* transition: background-color 100ms linear; */
}

.loader {
  background-color: #0f0;
  color: black;
}
